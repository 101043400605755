import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/RootReducer';
import { getCommunityStats, setViewModeForPicks } from '../../redux/actions/ConfidenceActions';

const options = ['My Results', 'Community Picks'];

export default function MyPicksAndStatsDropdown() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const {communityStats} = useSelector((state: RootState) => state.confidence)
  const pickable = useSelector((state: RootState) => state.confidence.matchups[0]?.pickable ?? true)
  const dispatch = useDispatch()

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index === 1 && (communityStats.length === 0 || !communityStats)) {
      dispatch(getCommunityStats())
    }
    else dispatch(setViewModeForPicks(index === 0 ? 'my-picks' : 'community-picks'))
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{width: '100%'}}>
      <ButtonGroup sx={{width: '100%'}} variant="contained" ref={anchorRef} aria-label="split button">
        <Button sx={{flex: 1}} onClick={() => setOpen(!open)}>{options[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          width: '100%',
          zIndex: 10000,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 1 && pickable}
                      selected={index === selectedIndex}
                      onClick={(event) => {
                        handleMenuItemClick(event, index)
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}